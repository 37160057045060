import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { loaderBox, layout, content, messageText, errorText } from './catalog-download.module.scss';
import { ISitePageContext } from '../models/site-page.model';

import SEO from '../components/seo';
import { useTranslation } from '../hooks/use-translation';
import { useLocation } from '@reach/router';
import { newsletterDownload } from '../api/newsletter';
import { IResponseError, IError, getErrorsFromResponse } from '../utils/get-form-errors';
import { config } from '../config';

import Loader from '../components/atoms/loader';
import MainLayout from '../layouts/main-layout';
import Title from '../components/atoms/title';

const { translationKeys, queryParamsKeys, statusMap } = config;
const logoSrc = '../assets/images/logo-verona-dark.png';

interface ICatalogDownloadProps {
    readonly pageContext: ISitePageContext;
}

const CatalogDownload: React.FC<ICatalogDownloadProps> = ({ pageContext }) => {
    const [status, setStatus] = useState<string>(statusMap.loading);
    const [message, setMessage] = useState<IError[] | string>('');

    const { t } = useI18next();
    const { label } = useTranslation(pageContext, translationKeys.pageContext);
    const { search } = useLocation();
    const keyValue = new URLSearchParams(search).get(queryParamsKeys.key);

    useEffect(() => {
        if (!keyValue) {
            setStatus(statusMap.error);
            setMessage(t('catalogDownload.error.noKey'));
            return;
        }
        newsletterDownload(keyValue)
            .then(() => {
                const link = document.createElement('a');
                link.href = config.endpoints.newsletter.download(keyValue);
                link.setAttribute('_target', 'blank');
                link.click();

                setStatus(statusMap.success);
                setMessage(t('catalogDownload.success'));
            })
            .catch((err: IResponseError) => {
                setStatus(statusMap.error);
                setMessage(getErrorsFromResponse(err, t));
            });
    }, []);

    return (
        <>
            <SEO title={label} />
            {status === statusMap.loading && (
                <div className={loaderBox}>
                    <StaticImage placeholder="blurred" src={logoSrc} alt={t('verona.logo.alt')} />
                    <Loader />
                </div>
            )}
            {status !== statusMap.loading && (
                <MainLayout className={layout}>
                    <div className={content}>
                        <Title
                            size="small"
                            className={`
                                ${messageText} 
                                ${status === statusMap.error ? errorText : ''}
                            `}
                        >
                            {Array.isArray(message)
                                ? message.map((item) => {
                                      return (
                                          <>
                                              {item.content}
                                              <br />
                                          </>
                                      );
                                  })
                                : message}
                        </Title>
                    </div>
                </MainLayout>
            )}
        </>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default CatalogDownload;
